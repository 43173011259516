<template>
  <div v-if="appConfig && dataset">
    <v-row
      wrap
      no-gutters
      style="min-height: 300px"
    >
      <v-col
        cols="12"
        xs="12"
      >
        <v-select
          v-model="dataset.table_name"
          item-value="table_name"
          item-text="name"
          :items="appConfig.datasets"
          :label="dataset.label"
          clearable
          @change="datasetSelected"
        />
      </v-col>
      <template v-if="dataset.axis === 'y'">
        <v-col
          cols="12"
          xs="12"
          class="pb-2"
        >
          <h3 class="caption">
            Set Dates
          </h3>
          <date-picker
            v-model="dataset.start_date"
            placeholder="Start Date"
            value-type="format"
            class="mr-1"
            @change="emitUpdated"
          />
          <date-picker
            v-model="dataset.end_date"
            placeholder="End Date"
            value-type="format"
            @change="emitUpdated"
          />
        </v-col>
      </template>
      <v-col
        v-if="loading"
        cols="12"
        class="text-center pa-5"
      >
        <v-progress-circular
          :size="70"
          :width="7"
          color="purple"
          indeterminate
        />
      </v-col>
      <template
        v-for="parameter in parameters"
        v-else
      >
        <v-col
          :key="parameter.uiKey"
          cols="12"
        >
          <v-autocomplete
            v-if="parameter.display === 'multi-select'"
            :label="parameter.name"
            v-model="dataset.filters[parameter.column]"
            auto-select-first
            chips
            clearable
            deletable-chips
            multiple
            small-chips
            item-text="value"
            item-value="value"
            :items="parameter.list"
            @change="emitUpdated"
          />
          <template v-if="parameter.display === 'radio'">
            <div>
              {{ parameter.name }}
            </div>
            <v-radio-group
              v-model="parameter.value"
              row
            >
              <v-radio
                v-for="item in parameter.list"
                :key="item.value"
                :label="item.value"
                :value="item.value"
              />
            </v-radio-group>
          </template>
        </v-col>
      </template>
      <v-col
        v-if="dataset"
        cols="12"
      >
        <div>
          Where there are multiple values for a variable at a
          <strong>location</strong> and <strong>date</strong>, take the: <br>
        </div>
        <v-radio-group
          v-model="dataset.for_multiple_values"
          row
          @change="emitUpdated"
        >
          <v-radio
            label="Sum"
            value="sum"
          />
          <v-radio
            label="Mean"
            value="avg"
          />
          <v-radio
            label="Minmium Value"
            value="min"
          />
          <v-radio
            label="Maximum Value"
            value="max"
          />
        </v-radio-group>
      </v-col>
      <v-col
        v-if="dataset"
        cols="12"
      >
        <div>
          Where there are missing values for a <strong>location</strong> and
          <strong>date</strong>, take the following action: <br>
        </div>
        <v-radio-group
          v-model="dataset.for_missing_value"
          row
        >
          <v-radio
            label="Remove values"
            value="remove_values"
          />
          <v-radio
            label="Fill with zero"
            value="fill_with_zero"
          />
          <v-radio
            label="Fill with mean"
            value="fill_with_mean"
          />
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        xs="12"
      >
        <v-btn
          color="primary"
          @click="nextStep(dataset.order)"
        >
          Continue
        </v-btn>
        <!-- <v-btn text @click="emitBuild"> Test Query </v-btn>
        <v-btn text> Cancel </v-btn> -->
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
// import DatePicker from 'vuejs-datepicker';

import ApiService from '../../services/api.service';

const modelNamespace = createNamespacedHelpers('model');

export default {
  components: {
    // DatePicker,
  },
  props: ['input'],
  data() {
    return {
      iModel: {
        dataset: null,
      },
      lastSelectedTable: null,
      dataset: null,
      loading: false,
      appConfig: null,
      multipleValueAction: 'sum',
      missingValueAction: 'remove_values',
      parameters: [],
    };
  },
  mounted() {
    this.appConfig = this.$store.state.appConfig;
    if (this.input && this.input.dataset) {
      this.dataset = { ...this.input.dataset };
      if (this.dataset.table_name) {
        this.lastSelectedTable = this.dataset.table_name;
        this.datasetSelected(this.dataset.table_name);
      }
      // this.$emit('updated', this.dataset);
    }
  },
  methods: {
    ...modelNamespace.mapActions([]),
    ...modelNamespace.mapMutations(['mSetStep']),
    emitBuild() {
      this.$emit('build', this.dataset);
    },
    nextStep() {
      if (this.dataset.axis === 'y' && !this.dataset.table_name) {
        alert('Select data to predict');
        return false;
      }
      this.$emit('next', this.dataset);
    },
    async loadParameter(table, parameter) {
      try {
        parameter.key = parameter.code;
        const q = `SELECT distinct(${parameter.column}) AS value from ${table}`;
        parameter.loading = true;
        const response = await ApiService.post('/raw_sql/', { q });
        // parameter.key = `${parameter.name}loaded`;
        parameter.uiKey = `${parameter.name}loaded`;
        parameter.list = response.data;
      } catch (error) {}

      parameter.loading = false;
    },
    async loadParameters(table, p) {
      // this.dataset.filters = {};
      // this.dataset.lists = {};
      console.log('p', p);
      const parameters = [...p];

      const requests = parameters.map((parameter) => {
        const q = `SELECT distinct(${parameter.column}) AS value from ${table}`;
        return ApiService.post('/raw_sql/', { q });
      });

      const dataset = this.dataset;
      // dataset.lists = {};

      this.loading = true;
      const that = this;
      const responses = await Promise.all(requests);
      responses.forEach((response, key) => {
        // if (that.model && that.model[])
        if (dataset.remember_filters.includes(parameters[key].code)) {
          dataset.lists[parameters[key].code] = response.data;
          // console.log('dataset.lists[parameters[key].code]', dataset.lists[parameters[key].code]);
        }
        parameters[key].list = response.data;
        parameters[key].uiKey = `${parameters[key].name}loaded`;
      });
      this.parameters = parameters;
      this.loading = false;
      this.$emit('updated', this.dataset);
    },
    emitUpdated() {
      this.$emit('updated', this.dataset);
    },
    datasetSelected(table_name) {
      const dataset = this.appConfig.datasets.find(
        (item) => item.table_name === table_name,
      );
      if (table_name !== this.lastSelectedTable) {
        this.dataset.filters = {};
      }
      this.lastSelectedTable = table_name;
      this.loadParameters(dataset.table_name, dataset.parameters);
      // this.$emit('updated', this.dataset);
      // console.log('dataset', dataset);
    },
  },
};
</script>
